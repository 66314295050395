@keyframes slideInOut {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .background-slider {
    animation: slideInOut 1s forwards;
  }
  .background-slider.new {
    animation-name: slideInOutNew;
  }
  
  @keyframes slideInOutNew {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .bg-gradient-black-darkgray {
    background-image: linear-gradient(to right, #000, #333);
  }  
  
  .bg-gradient-dark-classy {
    background-image: linear-gradient(to right, #0f2027, #203a43, #2c5364);
  }

  .bg-gradient-dark-classy-v2 {
    background-image: linear-gradient(to right, #0f2027, #3b4453, #0f2027);
  }

  /* custom-styles.css */
.bg-gradient-dark-classy-v3 {
  background-image: linear-gradient(to right, rgba(15, 32, 39, 0), #3b4453, rgba(15, 32, 39, 0));
}
